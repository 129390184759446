<template>
  <div class="page-header">
    <div class="page-header__title">
      <router-link v-for="(breadcrumb, index) in pageHeaderData.breadcrumbs" :key="`breadcrumb-${index}`" :to="breadcrumb.routeObj">
        {{ breadcrumb.label }}
        <span>
          >>
        </span>
      </router-link>
      <span v-if="pageHeaderData.preTitle.length" class="pre-title">{{ pageHeaderData.preTitle }}:&nbsp;</span>
      <span v-if="pageHeaderData.title && pageHeaderData.title.length" class="title">{{ pageHeaderData.title }}</span>
    </div>

    <div class="page-header__btns">
      <router-link
        :to="button.link ? button.link : ''"
        :class="['btn-wrapper', button.class]"
        :key="btnIndex"
        :target="button.target || '_self'"
        v-for="(button, btnIndex) in pageHeaderDataBtnList"
      >
        <div class="btn-item" v-if="button.type === 'label'">
          {{ button.label }}
        </div>

        <Button
          class="btn-item"
          v-if="button.type === 'button'"
          :style="button.style"
          :variant="button.variant"
          :label="button.label"
          :disabled="button.disabled"
          @click="button.action ? button.action() : () => {}"
        />

        <div class="btn-item" v-if="button.type === 'icon'">
          <font-awesome-icon
            :icon="iconName(button.icon)"
            :style="button.style"
            @click="button.action ? button.action() : () => {}"
          />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
// import {  } from "@fortawesome/free-solid-svg-icons";
import { customCog, customRedo } from "@/assets/icons";
library.add(customCog, customRedo);

import { mapState } from "vuex";

export default {
  name: "PageHeader",
  components: {
    Button,
  },
  computed: {
    ...mapState("helper", {
      pageHeaderData: "pageHeaderData",
    }),
    pageHeaderDataBtnList() {
      return this.pageHeaderData.btnList.filter((btn) => !btn.hidden);
    },
  },
  methods: {
    iconName(icon) {
      if (icon === "" || icon === undefined) return "custom-history";
      return icon;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid $light_gray;
  border-right: 1px solid $light_gray;
  background-color: $white;
  padding-left: 15px;
  padding-right: 15px;

  &__title {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 55px;
    display: flex;
    align-items: center;

    // .pre-title {}

    .title {
      color: $light_text;
    }

    a {
      color: $light_text;
      display: block;

      margin-right: 5px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__btns {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    .btn-wrapper {
      text-decoration: none;
      color: $dark_text;
    }

    .current {
      &.btn-type-label .btn-item {
        color: $purple;
        background-color: $background;
        border-radius: 3px;
      }

      &.btn-type-icon {
        color: $purple;
      }
    }

    .btn-type-label {
      padding: 0 4px;
      cursor: pointer;
      border-left: 1px solid $light_gray;

      &:first-child {
        border-left: none;
      }

      .btn-item {
        font-size: 0.875rem;
        font-weight: 500;
        padding: 4px 12px;
        @include transition--ease-out;

        &:hover {
          color: $purple;
          background-color: $background;
          border-radius: 3px;
        }
      }
    }

    .btn-type-button {
      .btn-item {
        font-size: 0.875rem;
        font-weight: 500;
        padding: 4px 12px;
        margin: 0 7px;
      }
    }

    .btn-type-icon {
      cursor: pointer;
      @include transition--ease-out;

      &:hover {
        opacity: 0.7;
      }

      .btn-item {
        font-size: 1.125rem;
        padding: 0 12px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
</style>
